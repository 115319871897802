import React from 'react';
import { 
  Button,
  Col,
  Row, 
  // Modal 
} from 'antd';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

export const Work = () => {
  const matches = useMediaQuery('(min-width: 768px)');
  return (
    <Row id="main-works" gutter={24} className='flex-display'>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }}>
        <div className="block worksBlock">
          <div className="container-fluid">
            <div className="titleHolder">
              <a className="block-anchor" id="works" href="#works">
                Pseudo anchor
              </a>
              <h2>How it Works?</h2>
              <h2>Do you want to submit your streams or website?</h2>
              <p style={{margin: '10px'}}>
                If you would like to submit your streaming website which offer free live streams, you can reach out to
                us at the email given below. We will add your website on our list of streamers. If your stream provides
                better service and user experience than the others, your postion on the list of streamers will rise automatically to
                the top.
              </p>
            </div>
              <div className="contentHolder">
                <Button type='primary' size={matches ? 'large': 'middle'}>
                  Contact: info.streameast@gmail.com
                </Button>
              </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
