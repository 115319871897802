import React, { useEffect, useState } from 'react';
import { 
  Col,
  Row,
  // Carousel,
  // Col,
  // Divider,
  // List,
  // Row,
  Spin,
  Tabs,
  Typography,
  // Tooltip,
  // Typography,
} from 'antd';
import { useMediaQuery } from '../../../hooks/MediaQuery.hook';
// import { styles } from '../../../styles';
// import { Link } from 'react-router-dom';
import { getCompetitions } from '../../../apis/fixture.api';
import { styles } from '../../../styles';
import { Competitions } from './Competitions/Competitions';
import { PAGE_TITLES } from '../../../utils/constant';

export const CompetitionPage = () => {
  const matches = useMediaQuery('(min-width: 768px)');
  const [competitions, setCompetitions] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const getAllCompetitions = async ()=> {
    setLoader(true);
    const response = await getCompetitions();
    setCompetitions(response?.data);
    setLoader(false);

  }

  useEffect(() => {
    document.title = PAGE_TITLES.COMPETITIONS;
    getAllCompetitions();
  }, []);

  return (
    <>
    {competitions?.map((competition: any, index: number) => (
    <>
    <Row gutter={24} style={styles.flexDisplay}>
      <Col span={22}>
      <Typography.Title level={1} style={{color: '#F0F0F0'}}>TOP {competition?.type?.toUpperCase()}</Typography.Title>
      </Col>
    </Row>
    <Row key={index} gutter={24} style={styles.flexDisplay}>
      <Col span={22}>
        <Tabs style={{color: '#F0F0F0', fontWeight:'600', fontSize: matches ? '24px !important': '18px'}} 
        defaultActiveKey={competition?.type === 'leagues' ? '7': '0'} items={competition?.data?.map((c: any, ind: number)=>{
          return {
            key: `${ind}`,
            label: c?.name,
            children: <Competitions competition={c}></Competitions>,
          }
        })}/>
      </Col>
    </Row>
    </>
    
    ))}
    <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              minHeight: '425px'
          }}>
          {loader ? <Spin /> : <></>}
      </div>
    </>
    );
};