import React, { useEffect, useState } from 'react';
import { 
  Carousel,
  Col,
  Divider,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { styles } from '../../styles';
import { Link } from 'react-router-dom';
import { getTransfers } from '../../apis/fixture.api';
import { HorizontalFeed } from './HorizontalBlogs';

interface Blog {
  _id: string;
  title: string;
  datetime: string; // Use `Date` if you're handling it as a Date object in your app
  id: string;
  image: string;
  category: string;
}

export const Transfer = () => {
  const matches = useMediaQuery('(min-width: 768px)'); // `true` for tablets and above
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const getAllBlogs = async () => {
    setLoader(true);
    const response = await getTransfers();
    setBlogs(response.data);
    setLoader(false);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <>
      <div id="transfer" className="homeBlock">
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} lg={20}>
            {/* Header */}
            <div style={{ textAlign: 'left', padding: '0px', color: '#fff', marginTop: !matches ? '10%': '' }}>
              <Typography.Title
                level={1}
                style={{ color: '#fff', fontSize: matches ? '32px' : '20px' }}
              >
                LATEST TRANSFERS
              </Typography.Title>
            </div>

            {/* Carousel and Secondary Blogs */}
            <Row gutter={[16, 16]}>
              {/* Carousel */}
              <Col xs={24} lg={16}>
                <Carousel dotPosition="bottom">
                  {blogs?.slice(0, 10).map((item: Blog, index: number) => (
                    <div key={index} style={{ textAlign: 'center' }}>
                      <Link to={`/blogs/${encodeURIComponent(item?.title)}`}>
                        <img
                          style={{
                            ...styles.mainBlogImage,
                            width: '100%',
                            height: matches ? '500px' : '200px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                          }}
                          src={item.image}
                          alt={item?.title}
                        />
                        <div>
                          <p style={{
                              ...styles.slidePostDescription, 
                              paddingLeft: matches ? '10px': '0px', 
                              margin: '0px',
                              textAlign: 'center'
                              }}>{item?.title}
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </Col>

              {/* Secondary Blogs */}
              <Col xs={24} lg={8} style={{ maxHeight: '500px', overflowY: 'scroll', scrollbarWidth: 'thin'}}>
                {blogs?.slice(10, 30).map((item: Blog, index: number) => (
                  <Row key={index} gutter={[8, 8]} align="top">
                    <Col xs={12} lg={12}>
                      <img
                        style={{
                          ...styles.transferImage,
                          width: '100%',
                          height: matches ? 'auto' : '75px',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                        src={item.image}
                        alt={item?.category}
                      />
                    </Col>
                    <Col xs={12} lg={12}>
                      <Link to={`/blogs/${encodeURIComponent(item?.title)}`}>
                        <Typography.Text
                          style={{
                            fontSize: matches ? '14px' : '12px',
                            color: '#fff',
                            fontFamily: 'cursive',
                          }}
                        >
                          {item.title}
                        </Typography.Text>
                        {/* <p
                          style={{
                            ...styles.blogCategory,
                            fontSize: matches ? '12px' : '10px',
                            margin: '4px 0',
                          }}
                        >
                          {item.category}
                        </p> */}
                      </Link>
                    </Col>
                    <Divider style={{ margin: '1px 0' }} />

                  </Row>
                ))}
              </Col>
            </Row>

            {/* Horizontal Feed */}
            <HorizontalFeed blogs={blogs?.slice(30, 50)} />
          </Col>
        </Row>
      </div>

      {/* Loader */}
      {loader && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}
        >
          <Spin />
        </div>
      )}
    </>
  );
};
