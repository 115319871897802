import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import { useMediaQuery } from '../../../../../hooks/MediaQuery.hook';
import { styles } from '../../../../../styles';
import { Fixtures } from '../../Fixture';
import { MainBlog } from '../../firstBlog';
import { getCompetitionByName } from '../../../../../apis/fixture.api';
import { useParams } from 'react-router-dom';
import { getCompetitionTitle } from '../../../../../utils';

export const Competition = () => {
  const matches = useMediaQuery('(min-width: 768px)');
  const [competition, setCompetition] = useState<any>();
  const { competitionName } = useParams();

  const [loader, setLoader] = useState<boolean>(false);

  const getCompetition = async ()=> {
    setLoader(true);
    const filter = {
      competitionName: competitionName
    }
    const response = await getCompetitionByName(filter);
    setCompetition(response.data);
    setLoader(false);

  }

  useEffect(() => {
      const titleStr = competitionName ?? '';
      document.title = getCompetitionTitle(titleStr);
      getCompetition();
  }, []);

  return (
    <>
      {!loader && <div id="competitionDetail">
        <Row gutter={[16, 24]}> {/* Adjusted gutter for better spacing */}
          <Col xs={24} sm={22}> {/* Adjusted span for responsiveness */}
            <Row gutter={[18, 24]}>
              <Col xs={24} sm={24} md={14}>
                <div style={{ textAlign: 'center', padding: '0px' }}>
                  <Typography.Title
                    level={1}
                    style={{
                      ...styles.flexDisplay,
                      color: '#fff',
                      marginTop: '50px'
                    }}
                  >
                    {competition?.image && <img
                      src={competition?.image}
                      alt={competition?.name}
                      style={{ width: '60px', paddingRight: '5px' }}
                    />}
                    {competition?.name?.toUpperCase()}
                  </Typography.Title>
                  <MainBlog blog={competition?.blogs[0]}></MainBlog>
                </div>
              </Col>
              <Col xs={24} sm={24} md={10} style={{ marginTop: matches ? '56px' : '0px' }}>
                {competition?.fixtures.length && <Fixtures competition={competition}></Fixtures>}
                {!competition?.fixtures.length && <Row gutter={24} style={{ ...styles.flexDisplay}}>
                  <Typography.Text style={styles.noData}>No Fixtures Found</Typography.Text>
                </Row>}
              </Col>
            </Row>
            { <Row gutter={[16, 24]} style={{ marginTop: '10px' }}>
              {competition?.blogs.length && competition?.blogs?.slice(1, competition?.blogs.length)?.map((leagueMainBlog: any, index: number) => {
                return (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    <MainBlog blog={leagueMainBlog}></MainBlog>
                  </Col>
                );
              })}
              {!competition?.blogs.length && <Typography.Text style={styles.noData}>No Feed</Typography.Text>}
            </Row>}
            { <Row gutter={24} style={{ ...styles.noData}}>
              
            </Row>}
          </Col>
        </Row>
      </div>}
      <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
          }}>
          {loader ? <Spin /> : <></>}
      </div>
    </>
  );
};
