import { doPost } from '../utils/request';

export const getBlogs = async () => {
  try {
    const response = await doPost('/hr', {});
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getTransfers = async () => {
  try {
    const response = await doPost('/thr', {});
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getCompetitions = async () => {
  try {
    const response = await doPost('/competitions', {});
    return response;
  } catch (err: any) {
    return err;
  }
};

export const getCompetitionByName = async (filter: any) => {
  try {
    const response = await doPost('/competitionByName', filter);
    return response;
  } catch (err: any) {
    return err;
  }
};

export const blogByTitle = async (title: string|undefined) => {
  try {
    const response = await doPost('/hrr',{title: title});
    return response;
  } catch (err: any) {
    return err;
  }
};