import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useMediaQuery } from '../../../../hooks/MediaQuery.hook';
import { styles } from '../../../../styles';
import { MoreButton } from '../MoreButtonComponent';
import { Fixtures } from '../Fixture';
import { MainBlog } from '../firstBlog';
import { Link } from 'react-router-dom';

const buttonStyles = {
  moreButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
    fontSize: '14px'
  }
};

interface competitionsProps {
  competition?: any;
}

export const Competitions: React.FC<competitionsProps> = ({ competition }) => {
  const matches = useMediaQuery('(min-width: 768px)');
  const competitionsComponent = window.location.pathname != '/';

  return (
    <>
      <div id="competitions">
        <Row gutter={[16, 24]}> {/* Adjusted gutter for better spacing */}
          <Col xs={24} sm={22}> {/* Adjusted span for responsiveness */}
            <Row gutter={[18, 24]}>
              <Col xs={24} sm={24} md={14}>
                <div style={{ textAlign: 'center', padding: '0px' }}>
                  <Typography.Title
                    level={2}
                    style={{
                      ...styles.flexDisplay,
                      color: '#fff',
                      marginTop: '50px'
                    }}
                  >
                    {competition?.image && <img
                      src={competition?.image}
                      alt={competition?.name}
                      style={{ width: '45px', paddingRight: '5px'}}
                    />}
                    <Link style={{color: '#fff'}} to={`/competitions/${competition?.name}`}>{competition?.name?.toUpperCase()} FEED</Link>
                  </Typography.Title>
                  <MainBlog blog={competition?.blogs[0]}></MainBlog>
                  {!competitionsComponent && <MoreButton buttonStyle={!competition?.blogs?.length ? buttonStyles:{}} url={`/competitions/${competition?.name}`}></MoreButton>}
                </div>
              </Col>
              <Col xs={24} sm={24} md={10} style={{ marginTop: matches ? '56px' : '0px' }}>
                <Fixtures competition={competition}></Fixtures>
                {!competitionsComponent && <MoreButton buttonStyle={ !competition?.fixtures?.length ? buttonStyles:{}} url={`/competitions/${competition?.name}`}></MoreButton>}
              </Col>
            </Row>
            {competitionsComponent && <Row gutter={[16, 24]} style={{ marginTop: '10px' }}>
              {competition?.blogs?.slice(1, 10)?.map((leagueMainBlog: any, index: number) => {
                return (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    <MainBlog blog={leagueMainBlog}></MainBlog>
                  </Col>
                );
              })}
            </Row>}
            {/* <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <MoreButton url={'/'}></MoreButton>
            </div> */}
          </Col>
        </Row>
      </div>
    </>
  );
};
