import React from 'react';
import { About } from './About';
import { Home } from './Hero';
import { FAQ } from './FAQ';
// import { Contact } from './Contact';
import { Feature } from './Feature';
import { Work } from './Work';
// import { CompetitionPage } from './CompetitionPage';

export const LandingPage = () => {
  return (
    <div className="main">
      <Home />
      {/* <CompetitionPage/> */}
      <Feature />
      <Work />
      <About />
      <FAQ />
      {/* <Contact /> */}
    </div>
  );
};
