export const AUTH_TOKEN = 'Authorization';

export const LOCAL_STORAGE = {
  ID_TOKEN: 'token',
  USER: 'user',
};

export const DEFAULT_TITLE = 'Breaking News Details';

export const PAGE_TITLES: any = {
  COMPETITIONS: 'StreameastApp - Latest Updates on Major Leagues Teams and upcoming Matches| streameastapp.net',
}

export const STREAMERS_DATA = [
  { title: 'Soccer', 
    imageURL: 'https://d22vy4ldq8bjtm.cloudfront.net/images/special/soccer_logo.webp',
    streamers: [
      {
        name: 'Streameast Soccer',
        link: 'https://streameast.soccer'
      },
      {
        name: 'Sportsfeed24 To Soccer',
        link: 'https://sportsfeed24.to/'
      },
      {
        name: 'Footybite streams',
        link: 'https://www.footybite.to/'
      },
      // {
      //   name: 'Soccer Streameast To',
      //   link: 'https://v3.streameast.to/soccer/schedule'
      // },
      {
        name: 'Sportsfeed24 Soccer',
        link: 'https://sportsfeed24.com'
      },
      {
        name: 'Soccerstreams 100',
        link: 'https://next.soccerstreams100.io/'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/soccerstreams'
      },
      {
        name: 'Crack Streams',
        link: 'https://index.crack-streams.com/soccer/schedule'
      },
      {
        name: 'TotalSportEk PRO',
        link: 'https://totalsportek.pro/soccer40/'
      },
      {
        name: 'TotalSportEk To',
        link: 'https://www.totalsportek.to/soccerstreams'
      },
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/soccerstreams'
      }
    ] 
  },
  { title: 'NFL & CFB & NCCA', 
    imageURL: 'https://d22vy4ldq8bjtm.cloudfront.net/incite-tube/nfl-logo.webp',
    streamers: [
      {
        name: 'Sportsfeed24 To NFL, CFB & NCAA',
        link: 'https://www.sportsfeed24.to/streams/nfl'
      },
      {
        name: 'Streameast NFL, CFB & NCCAB',
        link: 'https://the.streameast.app/nflstreams5'
      },
      {
        name: 'NFL Streameast Soccer',
        link: 'https://www.streameast.soccer/streams/NFL'
      },
      {
        name: 'Sportsfeed24 NFL, CFB & NCCA',
        link: 'https://www.sportsfeed24.com/fixtures/NFL/NFL'
      },
      {
        name: 'NFL Streams 100',
        link: 'https://nflbite.nflstreams100.com/'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/reddit-nfl-streams'
      },
      {
        name: 'Crack Streams',
        link: 'https://index.crack-streams.com/nfl/schedule'
      },
      {
        name: 'TotalSportEk PRO',
        link: 'https://totalsportek.pro/nfl40/'
      },
      {
        name: 'TotalSportEk To',
        link: 'https://www.totalsportek.to/nflstreams'
      },
      {
        name: 'Streameast Free NFL',
        link: 'https://www.streameastfree.com/nfl-streams'
      }
    ] 
  },
  { title: 'Boxing', 
    imageURL: 'https://d22vy4ldq8bjtm.cloudfront.net/images/special/boxing-logo.jpg',
    streamers: [
      {
        name: 'Streameast Boxing Streams',
        link: 'https://www.streameast.soccer/streams/Boxing'
      },
      // {
      //   name: 'Streameast To Boxing',
      //   link: 'https://v3.streameast.to/boxing/schedule1'
      // },
      {
        name: 'Boxing Streameast App',
        link: 'https://the.streameast.app/boxingstreams13'
      },
      {
        name: 'Sportsfeed24 Boxing',
        link: 'https://www.sportsfeed24.com/fixtures/Boxing/Boxing'
      },
      {
        name: 'Boxing Streams 100',
        link: 'https://heavyweight.boxingstreams100.com/'
      },
      {
        name: 'Sportsfeed24 To Boxing',
        link: 'https://www.sportsfeed24.to/streams/Boxing'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/boxingstreams'
      },
      {
        name: 'Crack Streams',
        link: 'https://index.crack-streams.com/boxing/schedule'
      },
      {
        name: 'TotalSportEk Boxing',
        link: 'https://totalsportek.pro/boxing40/'
      },
      {
        name: 'TotalSportEk To',
        link: 'https://www.totalsportek.to/boxing-streams'
      },
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/boxing-streams'
      }
    ] 
  },
  { title: 'F1', 
    imageURL: 'https://d22vy4ldq8bjtm.cloudfront.net/incite-tube/f1-logo.webp' ,
    streamers: [
      {
        name: 'Streameast F1',
        link: 'https://www.streameast.soccer/streams/F1'
      },
      {
        name: 'f1 streams 100',
        link: 'https://server3.f1streams100.com/'
      },
      {
        name: 'Footybite',
        link: 'https://www.footybite.to/f1-streams14'
      },
      {
        name: 'Sportsfeed24 To F1',
        link: 'https://www.sportsfeed24.to/streams/F1'
      },
      {
        name: 'Sportsfeed24 Formula 1',
        link: 'https://www.sportsfeed24.com/fixtures/F1/F1'
      },
      // {
      //   name: 'Streameast To',
      //   link: 'https://v3.streameast.to/f1/schedule'
      // },
      {
        name: 'Streameast App',
        link: 'https://the.streameast.app/f1streams9'
      },
      {
        name: 'TotalSportEk',
        link: 'https://totalsportek.pro/f1-streams40/'
      },
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/f1-streams'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/reddit-f1-streams'
      },
      {
        name: 'Crack-Stream',
        link: 'https://index.crack-streams.com/f1/schedule'
      }
    ] 
  },
  { title: 'NBA & WNBA', 
    imageURL: 'https://res.cloudinary.com/djfixkqyj/image/upload/v1725650327/Images/nba-logo_m4wnt6.webp' ,
    streamers: [
      {
        name: 'NBAStreams',
        link: 'https://v3.rnbastreams.com'
      },
      {
        name: 'NBA 100',
        link: 'https://nba-100.com/'
      },
      {
        name: 'Sportsfeed24 To',
        link: 'https://www.sportsfeed24.to/streams/nba'
      },
      {
        name: 'Streameast Streams',
        link: 'https://the.streameast.app/nbastreams82'
      },
      {
        name: 'Sportsfeed24',
        link: 'https://www.sportsfeed24.com/fixtures/NBA/NBA'
      },
      {
        name: 'NBA Streameast Soccer',
        link: 'https://www.streameast.soccer/streams/NBA'
      },
      {
        name: 'Streameast Free NBA Streams',
        link: 'https://www.streameastfree.com/nba-streams'
      },
      {
        name: 'NBA Total Sports',
        link: 'https://www.streameast.pro/nba-streams'
      },
      {
        name: 'WNBA Streameast App',
        link: 'https://the.streameast.app/wnbastreams4'
      },
      {
        name: 'NBA FootyBite',
        link: 'https://www.footybite.to/nba-streams15'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/redditnbastreams'
      },
    ] 
  },
  { title: 'MLB', 
    imageURL: 'https://res.cloudinary.com/djfixkqyj/image/upload/v1727105315/Images/mlb-logo_ctq1ay.webp' ,
    streamers: [
      {
        name: 'MLB Sportsfeed24 To',
        link: 'https://www.sportsfeed24.to/streams/mlb'
      },
      {
        name: 'TotalSportEk MLB',
        link: 'https://www.totalsportek.to/mlbstreams'
      },
      {
        name: 'MLB Streams 100',
        link: 'https://match.mlbstreams100.com/'
      },
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/mlb-streams'
      },
      // {
      //   name: 'Streameast To MLB',
      //   link: 'https://v3.streameast.to/mlb/schedule'
      // },
      {
        name: 'Streameast Soccer MLB',
        link: 'https://www.sportsfeed24.to/streams/MLB'
      },
      {
        name: 'Streameast MLB Streams',
        link: 'https://the.streameast.app/mlbstreams23'
      },
      {
        name: 'MLB Streams 100',
        link: 'https://match.mlbstreams100.com/'
      },
      {
        name: 'SportsFeed24',
        link: 'https://www.sportsfeed24.to/v2'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/redditmlbstreams'
      },
      {
        name: 'Crack-Stream',
        link: 'https://index.crack-streams.com/mlb/schedule'
      },
    ] 
  },
  { title: 'MMA/UFC', 
    imageURL: 'https://d22vy4ldq8bjtm.cloudfront.net/images/special/ufc-logo.webp' ,
    streamers: [
      {
        name: 'Sportsfeed24 MMA/UFC ',
        link: 'https://www.sportsfeed24.com/fixtures/UFC/UFC'
      },
      {
        name: 'Streameast MMA/UFC',
        link: 'https://the.streameast.app/boxingstreams13'
      },
      {
        name: 'Streameast Soccer',
        link: 'https://www.streameast.soccer/streams/UFC'
      },
      {
        name: 'Streameast Free MMA/UFC',
        link: 'https://www.streameastfree.com/ufc-streams'
      },
      {
        name: 'MMA Streams 100',
        link: 'https://m.mmastreams100.com/'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/cc-mmastreams'
      },
    ] 
  },
  { title: 'NHL', 
    imageURL: 'https://res.cloudinary.com/djfixkqyj/image/upload/v1727105315/Images/nhl-logo_ekq8iz.webp' ,
    streamers: [
      {
        name: 'NHL Streams 100',
        link: 'https://new.nhlstreams100.com/'
      },
      {
        name: 'TotalSportek To',
        link: 'https://www.totalsportek.to/nhlstreams'
      },
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/nhl-streams'
      },
      // {
      //   name: 'Streameast NHL Streams ',
      //   link: 'https://v3.streameast.to/nhl/schedule'
      // },
      {
        name: 'Sportsfeed24',
        link: 'https://www.sportsfeed24.com/fixtures/NHL/NHL'
      },
      {
        name: 'Sportsfeed24 NHL',
        link: 'https://www.sportsfeed24.to/streams/nhl'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/redditnhlstreams'
      }
    ] 
  },
  { title: 'MOTOGP', 
    imageURL: 'https://res.cloudinary.com/djfixkqyj/image/upload/v1723985308/Images/MotoGP_luvsfq.webp' ,
    streamers: [
      {
        name: 'Motogp Streams 100',
        link: 'https://main.motogpstreams100.com/'
      },
      {
        name: 'Sportsfeed24 Motogp',
        link: 'https://www.sportsfeed24.com/fixtures/motogp/MOTOGP'
      },
      {
        name: 'TotalSportEk MotogpStreams',
        link: 'https://totalsportek.pro/motogp40/'
      },
      {
        name: 'MotoGP Streameast Soccer',
        link: 'https://www.streameast.soccer/streams/Motogp'
      },
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/motogp-streams'
      },
      {
        name: 'FootyBite',
        link: 'https://motogp.footybite.to/'
      },
    ] 
  },
  { title: 'Tennis', 
    imageURL: 'https://d22vy4ldq8bjtm.cloudfront.net/images/special/tennis_logo.webp' ,
    streamers: [
      {
        name: 'Sportsfeed24',
        link: 'https://www.sportsfeed24.com/fixtures/tennis/tennis'
      },
      {
        name: 'Streameast Tennis',
        link: 'https://www.streameast.soccer/streams/Tennis'
      },
      {
        name: 'TotalSportEk Tennis',
        link: 'https://totalsportek.pro/tennis40/'
      },
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/tennis-streams'
      },
      {
        name: 'Sportsfeed24 To',
        link: 'https://www.sportsfeed24.to/streams/tennis'
      },
      {
        name: 'FootyBite',
        link: 'https://tennis.footybite.to/'
      }
    ] 
  },
  { title: 'Cricket', 
    imageURL: 'https://d22vy4ldq8bjtm.cloudfront.net/images/special/cricket_logo.webp' ,
    streamers: [
      {
        name: 'FootyBite',
        link: 'https://www.footybite.to/cricket-streams17'
      },
      {
        name: 'TotalSportEk',
        link: 'https://totalsportek.pro/cricket-streams40/'
      },
      {
        name: 'Sportsfeed24 To',
        link: 'https://www.sportsfeed24.to/streams/Cricket'
      },
      {
        name: 'Streameast',
        link: 'https://www.streameast.soccer/streams/Cricket'
      },
      {
        name: 'Sportsfeed24',
        link: 'https://www.sportsfeed24.com/fixtures/Cricket/Cricket'
      },
      {
        name: 'WebCric',
        link: 'https://webcric.com/'
      },
    ] 
  },
  { title: 'WWE', 
    imageURL: 'https://res.cloudinary.com/djfixkqyj/image/upload/v1727109384/Images/wwe-logo_spehzr.webp' ,
    streamers: [
      {
        name: 'Streameast Free',
        link: 'https://www.streameastfree.com/wwe-streams'
      },
      {
        name: 'Streams 100',
        link: 'https://next.soccerstreams100.io/'
      },
      {
        name: 'Sportsfeed24',
        link: 'https://sportsfeed24.com'
      },
      {
        name: 'Streameast',
        link: 'https://the.streameast.app'
      },
      {
        name: 'FootyBite',
        link: 'https://footybite.to'
      },
      {
        name: 'SportSurge',
        link: 'https://v2.sportsurge.to/wwestreams'
      },
    ] 
  }
];

export const HOME_CONTENT = [
  {
    key: '1',
    title: 'What is StreameastApp Network?',
    content: 'A platfprm to help you choose the best streaming site to watch the sport you love. Given today’s world, when sports fans have to be supplied with coverage of the games they follow in real time, StreamEast stands out as the best option in terms of free live sports streaming. StreamEast is a credible network of applications for watching live sports streams with an extensive list of sports and leagues to meet the requirements of sports enthusiasts from all over the world. Compatible for both mobile and web, these applications provides free streaming and sharp picture quality without interruption. The ease of its layout, coupled with a vast number of sports channels, makes them the best options for sports lovers who are searching for a good, free online streaming sites.',
  },
  {
    key: '2',
    title: 'The Key to Free Sport Streaming',
    content: 'The digital era has significantly impacted the consumption of sporting activities, and streaming is now a crucial service for passion in the world. Sport fans are also able to watch their favorite live events on web and mobile making it easier for them to follow the games at their own convenience. This instant access improves the general experience of watching sports streams which makes it important in the modern society. This network of websites cover almost every famous sports and millions of visit these sites daily to get access to live sports streams.',
  },
  {
    key: '3',
    title: 'What Will You Find?',
    content:  'This article seeks to offer a detailed guide to the StreamEast applications, as well as discuss about its functionality and performance. Whether you have been watching sports online or this is your first time using this platform, this article will help you understand why StreamEast is the best website to visit for free live sports streaming services, among other critical tips. What makes this application different form other streaming platforms is that it provides all the information about most popular streaming sites in the world. It also categorize best soccer streaming sites based on specific sports you want to watch.'
  },
];

export const HOME_FIXTURE_LENGTH = 3;
