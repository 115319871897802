export const formatDate = (dateString: string) => {
  if(dateString){
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short' }).format(date);
    } catch (error) {
      return '';
    }
  } else {
    return '';
  }
};

export const formatTime = (dateString: string) => {
  if (dateString) {
    try {
      const date = new Date(dateString); // Parse the date as UTC
      return new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      }).format(date); 
    } catch (error) {
      return '';
    }
  } else {
    return '';
  }
};